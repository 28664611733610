<template>
  <div style="background-color: #ebf0ff">
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" top :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center" style="font-size: 18px; font-family: montserratSemiBold">{{ msg
        }}</v-flex>
        <v-flex text-right>
          <v-btn :ripple="false" text @click="showSnackBar = false">
            <span style="font-size: 18px; font-family: montserratExtraBold">Ok
            </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap pt-10 justify-center>
      <v-flex xs10 pb-5>
        <v-layout wrap justify-start pb-5>
          <v-flex text-left px-0 pb-2>
            <span style="font-family: sedanRegular; font-size: 18px; cursor: pointer;">
              Block Crawler - Transaction level</span>
          </v-flex>
          <v-flex>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon style="cursor:pointer" v-bind="attrs" v-on="on" color="green" size="30px"
                  @click.native="$router.push('/blockCrawler')">mdi-arrow-left-circle</v-icon>
              </template>
              <span>Back to Block level</span>
            </v-tooltip></v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm3>
            <!-- <v-text-field v-model="keyword" append-icon="mdi-magnify"  @keyup.enter.native="getSearch()"
              style="border: 2px solid #7CB9E8; border-radius: 25px;" rounded label="Search"
              :hide-details="true"></v-text-field>
          -->
            <v-text-field v-model="keyword" solo dense @input="getData()" label="Search" single-line hide-details>
              <template v-slot:append>
                <v-icon @input="getData()"> mdi-magnify </v-icon>
              </template>
            </v-text-field>
          </v-flex>
        </v-layout>
        <v-data-table calculate-widths :headers="headers" @click:row="info" :items="blocks" hide-default-footer
          class=" .text--black resize" id="virtual-scroll-table">
          <template v-slot:[`item.create_date`]="{ item }">
            <span v-if="item.create_date">{{ item.create_date.slice(0, 10) }}
              {{ item.create_date.slice(11, 19) }}</span>
          </template>
          <template v-slot:[`item.archivalDate`]="{ item }">
            <span v-if="item.archivalDate">{{
              item.archivalDate.slice(0, 10)
            }}</span>
          </template>
          <template v-slot:[`item.privateFileHash`]="{ item }">
            <span v-if="item.privateFileHash">{{ item.privateFileHash }}
            </span>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination :length="pages" :total-visible="7" v-model="currentPage" color="#6AAFEE" circle></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 10,
      blocks: [],
      keyword: "",
      headers: [
        { text: "Status", value: "status", width: '100px' },
        { text: "ID", value: "txnNumber", width: '70px' },
        { text: "Asset ID", value: "assetID", width: '150px' },
        { text: "Type", value: "transactionTypeCode", width: '100px' },
        { text: "User ID", value: "ownerUid.userID", width: '120px' },
        { text: "Submitted at  ", value: "create_date", width: '180px' },
        { text: "Archive-Date", value: "archivalDate", width: '160px' },
        { text: "Private-Hash", value: "privateFileHash", width: '110px' },
      ],
    };
  },
  watch: {
    currentPage() {
      if (this.keyword == '') {
        this.$store.commit("changeCurrentPage", this.currentPage);
        this.getDataV1();
      }
      else
        this.getDataV1();

    },
    keyword() {
      this.currentPage = 1
      this.getDataV1();
    }
  },
  mounted() {
    if (this.$store.state.currentPage && this.keyword == '') {
      this.currentPage = this.$store.state.currentPage;
      this.getDataV1();
    } else this.getDataV1();
  },
  methods: {
    getSearch() {
      axios({
        url: "/block/transactions/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          blockId: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.blocks = response.data.data
            this.appLoading = false;
          } else {
            this.showsnackbar = false;
            this.blocks = [];
            this.msg = response.data.msg;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    // getData() {
    //   this.appLoading = true;
    //   axios({
    //     url: "/block/transactions/list",
    //     method: "GET",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     params: {
    //       limit: this.limit,
    //       page: this.currentPage,
    //       blockId: this.$route.query.id,
    //       keyword: this.keyword
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         this.appLoading = false;
    //         this.blocks = response.data.data;
    //         this.pages = Math.ceil(response.data.totalLength / this.limit);
    //       }
    //       else {
    //         this.showsnackbar = false;
    //         this.blocks = [];
    //         this.msg = response.data.msg;
    //         this.appLoading = false;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    getDataV1() {
      this.appLoading = true;
      axios({
        url: "/block/transactions/list/v1",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          blockId: this.$route.query.id,
          keyword: this.keyword
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.blocks = response.data.data;
            this.pages = Math.ceil(response.data.totalLength / this.limit);
          }
          else {
            this.showsnackbar = false;
            this.blocks = [];
            this.msg = response.data.msg;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    back() {
      this.$router.push("/blockCrawler")
    },
    info(id) {
      this.$router.push("/blockChainTransactionView?block=" + this.$route.query.block + '&txnNumber=' + id.txnNumber)
    }
    // ?id=" + id._id +
  },

};
</script>
<style>
.v-data-table-header {
  background-color: #c3e5ff;
  font-size: 20px;
  color: black;
}

.v-data-table .v-data-table-header tr th {
  font-size: 15px !important;
  color: black;
  font-family: montserratMedium;
}

.v-data-table tr td {
  font-size: 13px !important;
  color: black;
  font-family: montserratMedium;
}

.resize {

  min-width: 50px !important;
}

#virtual-scroll-table {
  max-width: 1200px;
  overflow: auto;
}
</style>